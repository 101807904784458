import { getRequestEvent, isServer } from 'solid-js/web';
import { createClient } from './create-client';

const browserClient = createClient();

export function getApiClient() {
	if (isServer) {
		return getRequestEvent()!.locals.client;
	}
	return browserClient;
}
