import { gql } from '../graphql/__generated__';

export const loggedInUserQuery = gql(`query loggedInUser {
  me {
		id
    email
    firstName
    lastName
    troonRewardsId
    card: oldTroonCardGroup {
      id
      name
    }
  }
  userRewards {
    availablePoints
    level
  }
}`);
